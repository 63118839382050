<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">银行卡绑定</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>银行卡绑定</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <div class="myIndex_info">
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">银行账户类型</div>
          <div>
            <el-radio v-model="bank_acct_type" label="1">对公</el-radio>
            <el-radio v-model="bank_acct_type" label="2">对私</el-radio>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">银行卡卡号</div>
          <div>
            <el-input v-model="card_id" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">银行卡对应的户名</div>
          <div>
            <el-input v-model="card_name" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="bank_acct_type == 2">
          <div class="myIndex_info_title font-14 text-333333">身份证证件号</div>
          <div>
            <el-input v-model="cert_id" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">银行预留手机号</div>
          <div>
            <el-input v-model="tel_no" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="bank_acct_type == 1">
          <div class="myIndex_info_title font-14 text-333333">银行编码</div>
          <div>
            <el-input v-model="bank_code" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">开户银行名称</div>
          <div>
            <el-input v-model="bank_name" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="bank_acct_type == 1">
          <div class="myIndex_info_title font-14 text-333333">
            银行账户开户银行所在省份编码
          </div>
          <div>
            <el-input v-model="prov_code" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="bank_acct_type == 1">
          <div class="myIndex_info_title font-14 text-333333">
            银行账户开户银行所在地区编码
          </div>
          <div>
            <el-input v-model="area_code" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="purseBox_btn" @click="verification">申 请 绑 卡</div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      card_id: "", //银行卡卡号
      card_name: "", //银行卡对应的户名
      cert_id: "", //证件号，银行账户类型为对私时，必填
      cert_type: "", //证件类型，仅支持：00-身份证，银行账户类型为对私时，必填
      tel_no: "", //手机号
      bank_code: "", //银行编码，详见附录 银行代码，银行账户类型对公时，必填
      bank_name: "", //开户银行名称
      bank_acct_type: "2", //银行账户类型：1-对公；2-对私
      prov_code: "", //银行账户开户银行所在省份编码 （省市编码），银行账户类型为对公时，必填
      area_code: "", //银行账户开户银行所在地区编码（省市编码），银行账户类型为对公时，必填
    };
  },
  mounted() {},
  methods: {
    // 绑定银行卡
    verification() {
      let that = this;
      req
        .post("center/createUser", {})
        .then((res) => {
          if (res.code != 200) {
            return;
          }
          let cert_type = "";
          if (that.bank_acct_type == 2) {
            cert_type = "00";
          }
          req
            .post("auth/bankLogin", {
              card_id: that.card_id,
              card_name: that.card_name,
              cert_id: that.cert_id,
              cert_type: cert_type,
              tel_no: that.tel_no,
              bank_code: that.bank_code,
              bank_name: that.bank_name,
              bank_acct_type: that.bank_acct_type,
              prov_code: that.prov_code,
              area_code: that.area_code,
            })
            .then((res) => {
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              that.$message.success(res.msg);
              setTimeout(() => {
                that.$router.go(-1);
              }, 600);
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_btn {
    width: 7.6875rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    text-align: center;
    font-size: 0.875rem;
    color: #ffffff;
    background: #ff524c;
    border-radius: 1.125rem;
    cursor: pointer;
    margin-left: 2.375rem;
  }
  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-sizing: border-box;
    color: #333333;
    display: inline-block;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    text-align: right;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  .myIndex {
    &_info {
      padding: 2.375rem 2.375rem 2rem;
      padding-right: 25rem;
      &_title {
        width: 12.5rem;
      }
    }
  }
}
</style>
